import { FormEvent, HTMLProps, useCallback, useState } from 'react';
import { useModalsStore, useUserStore } from 'stores';
import classNames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@react-hookz/web';

import editSrc from 'assets/icons/icon-edit.png';
import loginSrc from 'assets/icons/icon-login.svg';
import logoSrc from 'assets/logo.svg';
import { SearchBar } from 'components/searchbar/searchbar.component';
import { AdType } from 'constants/ad-type.enum';
import { UserMenu } from '../usermenu/usermenu.component';
import { SearchModal } from './components/search-modal.component';
import style from './header.module.scss';
import theme from 'styles/theme.module.scss';
import { useAdsStore } from 'services/ads.service';

interface HeaderProps extends HTMLProps<HTMLDivElement> {}

const Header = (props: HeaderProps) => {
  const { className } = props;
  const [query, setQuery] = useState<string>('');
  const [searchOpen, setSearchOpen] = useState(false);
  const isMobile = useMediaQuery(`screen and (max-width:${theme['breakpoint-s']})`);
  const [setFilter, setSearchstring] = useAdsStore((state) => [state.setFilter, state.setSearchstring]);

  const navigate = useNavigate();
  const location = useLocation();

  const user = useUserStore((state) => state.user);

  const setShowLogin = useModalsStore((state) => state.setShowLogin);
  const setShowRegistration = useModalsStore((state) => state.setShowRegistration);

  const handleLogin = useCallback(() => {
    setShowLogin(true);
  }, [setShowLogin]);

  const handleRegistration = useCallback(() => {
    setShowRegistration(true);
  }, [setShowRegistration]);

  const handleSearch = (event: FormEvent) => {
    event.preventDefault();
    setFilter({
      type: AdType.Service,
      placeId: user?.placeId ? [user.placeId, 0] : undefined,
    });
    setSearchstring(query);
    setSearchOpen(false);
    navigate('/search');
  };

  return (
    <div
      className={classNames(style.container, className)}
      style={props.style}
      onKeyDown={(e) => e.key === 'Escape' && setSearchOpen(false)}
    >
      <div className={style.bar}>
        <div className={style.leftColumn}>
          <div onClick={() => {
            if (location.pathname === '/') navigate('/search');
            else navigate('/');
          }}>
            <img src={logoSrc} alt="logo" className={style.logo} />
          </div>
          <button
            className={classNames(style.searchbutton, 'bullet menu darkblue')}
            aria-label="Öppna sökfält"
            aria-haspopup="dialog"
            onClick={() => setSearchOpen(!searchOpen)}
          >
            <SearchIcon />
          </button>

          <nav className={style.navigation}>
            <Link
              to="/search"
              onClick={() => {
                setSearchstring('');
                setFilter({ type: AdType.Service, placeId: user?.placeId ? [user.placeId, 0] : undefined });
              }}
            >
              Tjänster
            </Link>
            <Link
              to="/search"
              onClick={() => {
                setSearchstring('');
                setFilter({ type: AdType.Request, placeId: user?.placeId ? [user.placeId, 0] : undefined });
              }}
            >
              Förfrågningar
            </Link>
          </nav>
        </div>

        <div className={style.rightColumn}>
          <form className={style.searchBarContainer} onSubmit={(e) => handleSearch(e)}>
            <SearchBar
              variant="light"
              onChange={setQuery}
              onFocus={() => {
                isMobile && setSearchOpen(true);
              }}
            />
          </form>
          <SearchModal
            open={searchOpen}
            onClose={() => setSearchOpen(false)}
            query={query}
            onChange={setQuery}
            onSubmit={handleSearch}
          />
          {user ? (
            <>
              <UserMenu />

              <Link className={classNames('button red iconLeft', style.hideonsmall)} to="/create-ad">
                <img src={editSrc} alt=""></img>
                <span>Skapa inlägg</span>
              </Link>
              <Link className={classNames('button red bullet', style.hideonsmallup)} to="/create-ad">
                <img src={editSrc} alt="Skapa inlägg"></img>
              </Link>
            </>
          ) : (
            <>
              <button className={`link iconLeft ${style.login}`} onClick={handleLogin}>
                <img src={loginSrc} alt="login" />
                Logga in
              </button>
              <button className={classNames('primary', style.hideonsmall)} onClick={handleRegistration}>
                Skapa konto
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
