import React from 'react';
import { Link } from 'react-router-dom';

import carrouselSrc from 'assets/illustrations/carrousel.png';
import sellServiceImg from 'assets/illustrations/sell-services.png';
import buyServiceImg from 'assets/illustrations/buy-services.png';
import getInContact from 'assets/illustrations/getInContact.png';
import kommisionComplete from 'assets/illustrations/KommisionComplete.png';
import searchAndFind from 'assets/illustrations/searchAndFind.png';
import Tjänst from 'assets/Images/tjänst.png';
import Guide from 'assets/Images/Guide.png';

import { Column, Grid } from 'components/grid/grid.component';
import { LinkBubble } from 'components/grid/link-bubble';
import { WhiteBackground } from 'layouts/backgrounds/white-background/white-background.component';
import { Main } from 'layouts/main/main.component';
import { PageHeader } from 'layouts/page-header/page-header.component';
import { SearchHeader } from './components/search-header.component';

import { useModalsStore, useUserStore } from 'stores';
import { AdType } from 'constants/ad-type.enum';
import { useAdsStore } from 'services/ads.service';

import style from './landing-page.module.scss';

export const LandingPageView: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const setShowRegistration = useModalsStore((state) => state.setShowRegistration);
  const [clearFilter, setFilter] = useAdsStore((state) => [state.clearFilter, state.setFilter]);

  return (
    <Main backgroundComp={WhiteBackground}>
      <PageHeader>
        <Grid className={style.header}>
          <Column span={[6, 6, 12, 4]}>
            <SearchHeader />
          </Column>
          <Column span={[5, 5, 8, 4]} start={[8, 8, 3, 1]}>
            <div className={style.carrousel}>
              <img src={carrouselSrc} alt="Illustration av flera annonser som erbjuder olika typer av tjänster" />
            </div>
          </Column>
        </Grid>
      </PageHeader>

      <Grid id="bubbleRow" className={style.bubbleRow}>
        <Column span={[3, 3, 4, 3]} start={[3, 3, 2, 1]} id={style.bubbleRow}>
          {!user ? (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill skapa annonser"
              linkText="Skapa ett Svippokonto"
              linkUrl="/"
              onClick={() => setShowRegistration(true)}
              imageUrl={sellServiceImg}
            />
          ) : user.svipper ? (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill sälja mina tjänster"
              linkText="Skapa en annons"
              linkUrl="/create-ad"
              state={{ type: AdType.Service }}
              imageUrl={sellServiceImg}
            />
          ) : (
            <LinkBubble
              className={style.sellServices}
              title="Jag vill sälja mina tjänster"
              linkText="Bli en svippare direkt"
              linkUrl="/become-svippare"
              imageUrl={sellServiceImg}
            />
          )}
        </Column>
        <Column span={[3, 3, 4, 3]} start={[8, 8, 8, 1]}>
          <LinkBubble
            onClick={() => {
              clearFilter();
              setFilter({ type: AdType.Service });
            }}
            className={style.buyServices}
            title="Jag vill ha hjälp med något"
            linkText="Upptäck svippade tjänster"
            linkUrl="/search"
            imageUrl={buyServiceImg}
          />
        </Column>
      </Grid>

      <Grid className={style.processRow}>
        <Column span={[4, 4, 4, 3]}>
          <div className={style.processItem}>
            <img src={searchAndFind} alt="Utforska & Hitta" />
            <div>
              <span>Utforska & Hitta</span>
              <p>
                Bläddra igenom tjänster och välj det som passar ditt behov bäst. Inget krångel, bara ren och skär
                upptäckarglädje.
              </p>
            </div>
          </div>
        </Column>

        <Column span={[4, 4, 4, 3]}>
          <div className={style.processItem}>
            <img src={getInContact} alt="Kom i kontakt" />
            <div>
              <span>Kom i kontakt</span>
              <p>
                När du hittar rätt tjänst, ta kontakt med utföraren. Diskutera vad du behöver hjälp med, utan
                mellanhänder
              </p>
            </div>
          </div>
        </Column>

        <Column span={[4, 4, 4, 3]}>
          <div className={style.processItem}>
            <img src={kommisionComplete} alt="Uppdraget utförs" />
            <div>
              <span>Uppdraget utförs</span>
              <p>
                Kom överens om upplägget och låt utföraren ta hand om resten. Vid utfört uppdrag fakturerar svipparen.
              </p>
            </div>
          </div>
        </Column>
      </Grid>

      <div className={style.infoSection}>
        <div className={style.callToAction}>
          <h3>Upptäck din nästa tjänst</h3>
          <span>
            Det allra första steget till att bli en <br /> framgångsrik svippare är att skapa en attraktiv.
          </span>
          <Link
            to="/search"
            onClick={() =>
              setFilter({
                type: AdType.Service,
                placeId: user?.placeId ? [user.placeId, 0] : undefined,
                categories: [1, 2],
              })
            }
          >
            <button className="button red">Utforska</button>
          </Link>
        </div>
        <div className={style.fundersam}>
          <h3>Är du fundersam? 🤔</h3>
        </div>
        <div className={style.infoBoxes}>
          <div className={`${style.infobox} ${style.infobox1}`}>
            <div className={style.imageBox}>
              <img src={Guide} alt="Guide" />
            </div>
            <div className={style.infoText}>
              <div>
                <h6>Att vara en Svippare</h6>
                <h3>Din guide för att börja utföra jobb på Svippo</h3>
                <span>
                  Ta det första steget mot att bli en eftertraktad Svippare – börja med en profil som sticker ut.
                </span>
              </div>

              <div>
                <a href="#">Hur tar jag betalt?</a>
                <a href="#">Är Svippo kostnadsfritt?</a>
                <a href="#">Hur skapar jag en profil?</a>
              </div>
            </div>
          </div>
          <div className={`${style.infobox} ${style.infobox2}`}>
            <div className={style.imageBox}>
              <img src={Tjänst} alt="Tjänst" />
            </div>
            <div className={style.infoText}>
              <div>
                <h6>Att vara en beställare</h6>
                <h3>Hitta rätt tjänst för dina behov på Svippo</h3>
                <span>
                  Upptäck hur enkelt det är att förvandla dina projekt eller uppgifter till verklighet med hjälp av en
                  Svippare.
                </span>
              </div>

              <div>
                <a href="#">Hur bokar jag en tjänst?</a>
                <a href="#">Hur betalar jag för en tjänst?</a>
                <a href="#">Kan jag avboka en bokad tjänst?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};
