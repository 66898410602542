import { useState } from 'react';
import { Modal } from '../modal.component';
import { RegisterCompanyForm, RegisterPrivateForm } from '../../forms';
import { useModalsStore, useUserStore } from '../../../stores';

import style from './registration-modal.module.scss';
import { FormikHelpers } from 'formik';

interface RegistrationModalProps {
  onClose?: () => void;
  onSuccess?: (type: string) => void;
}

const RegistrationModal = (props: RegistrationModalProps) => {
  const { onClose, onSuccess } = props;

  const open = useModalsStore((state) => state.showRegistration);
  const setOpen = useModalsStore((state) => state.setShowRegistration);
  const setShowLogin = useModalsStore((state) => state.setShowLogin);
  const [setUser, setTokens] = useUserStore((state) => [state.setUser, state.setTokens]);

  const [type, setType] = useState('private');

  const handleFormTypeChange = (event: any) => {
    const newType = event.target.value;
    setType(newType);
  };

  const handleSubmit = (fields: { [key: string]: string }, helpers: FormikHelpers<{ [key: string]: string }>) => {
    const { setFieldError } = helpers;

    // TODO: Get URL from a environment variable and move REST API communication to a API service or something
    const url =
      type === 'private'
        ? `${process.env.REACT_APP_BACKEND_URL}/auth/register-private`
        : `${process.env.REACT_APP_BACKEND_URL}/auth/register-company`;

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(fields),
    })
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((data) => {
            if (data.name === 'SequelizeUniqueConstraintError') {
              setFieldError('email', 'E-posten är upptagen');
            }
            return;
          });
        }
        return response.json();
      })

      .then((response) => {
        if (response && response.name === 'SequelizeUniqueConstraintError') {
          setFieldError('email', 'E-posten är upptagen');
          return;
        }

        if (response && response.user && response.tokens) {
          setUser({ ...response.user, ads: [] });
          setTokens(response.tokens);
          setOpen(false);
          onSuccess?.(response.user.type);
        }
      })

      .catch((error) => {
        console.error('There was a problem with the registration:', error);
      });
  };

  const handleClose = () => {
    // Reset state and close
    setOpen(false);
    setType('private');
    onClose?.();
  };

  return (
    <Modal title={'Gå med i Svippo'} open={open} onClose={handleClose}>
      <div className={style.radioButtons} onChange={handleFormTypeChange}>
        <div>
          <input type="radio" name="registrationType" id="private" value="private" defaultChecked={true} />
          <label htmlFor="private">{'Gå med som privatperson'}</label>
        </div>

        <div>
          <input type="radio" name="registrationType" id="company" value="company" />
          <label htmlFor="company">{'Gå med som företag'}</label>
        </div>
        {/* Ta tillbaka!!! <div>
          <input type="radio" name="registrationType" id="ufCompany" value="ufCompany" />
          <label htmlFor="company">{'Gå med som UF-företag '}</label>
        </div> */}
      </div>

      {type === 'private' ? (
        <RegisterPrivateForm onValidSubmit={handleSubmit} />
      ) : (
        <RegisterCompanyForm onValidSubmit={handleSubmit} />
      )}

      <div className={style.footer}>
        <span>Har du redan ett konto?</span>
        <button
          className="link"
          onClick={() => {
            setOpen(false);
            setShowLogin(true);
          }}
        >
          Logga in
        </button>
      </div>
    </Modal>
  );
};

export { RegistrationModal };
