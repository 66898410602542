import { useState } from 'react';
import { useModalsStore, useUserStore } from '../../../stores';
import { Modal } from '../modal.component';
import { deleteAd, useAd } from 'services/ads.service';
import style from './delete-modal.module.scss';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface DeleteModalProps {
  onClose?: () => void;
  onSuccess?: (type: string) => void;
}

const DeleteModal = (props: DeleteModalProps) => {
  const { onClose } = props;
  const open = useModalsStore((state) => state.showDeleteAd);
  const setShowDeleteAd = useModalsStore((state) => state.setShowDeleteAd);
  const { user, setUser } = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const getAdIdFromPath = (pathname: string): string | null => {
    const match = pathname.match(/\/ads\/(\d+)/);
    return match ? match[1] : null;
  };

  const adId = getAdIdFromPath(location.pathname);

  const { data: ad, loaded: adLoaded } = useAd(adId || '');

  const handleDelete = async () => {
    if (!adId || !user || !user.id || !ad || !adLoaded) return;

    if (ad.userId !== user.id) {
      console.error('User does not own the ad');
      return;
    }

    try {
      setLoading(true);
      await deleteAd(adId);
      const newAds = user.ads.filter((adItem) => adItem.id !== Number(adId));
      setUser({
        ...user,
        ads: newAds,
      });
      navigate('/myprofile');
    } catch (error) {
      console.error('Error deleting ad:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setShowDeleteAd(false);
    onClose?.();
  };

  return (
    <Modal title={'Ta bort annonsen?'} open={open} onClose={handleClose}>
      <div>
        <span>Är du säker på att du vill ta bort annonsen?</span>
      </div>
      <div className={style.footer}>
        <button className="red  Large" onClick={handleDelete} disabled={loading}>
          Bekräfta borttagning
        </button>
        <button className=" Large" onClick={handleClose} disabled={loading}>
          Avbryt
        </button>
      </div>
    </Modal>
  );
};

export { DeleteModal };
